import { Alert, Box, Button, Divider, Grid, LinearProgress, List, ListItem, ListItemText, Paper, Snackbar, Stack, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import readXlsxFile from 'read-excel-file';
import { setRequisitions } from "../../store/transportRequisitionSlice";
import { userDetails, users } from "../../store/userSlice";
// import * as XLSX from 'https://cdn.sheetjs.com/xlsx-latest/package/xlsx.mjs';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";
import excelFile from "../../assets/Transport Bulk Upload v1.0.1.xlsx";
import { erpSystemURL, getRequest, postRequest, transportRequestURL } from "../../db/apiHandler";
import { devMode } from "../../db/devMode";
import { customerAddresses } from "../../store/addressSlice";
import { setAppVersion } from "../../store/appSlice";
import { formatDate, formatDateWithoutHours, formatDateWithoutHoursUKFormat } from "../../utils/DateFormat";
import { poscodePattern } from "../../utils/Postcode";
import { toFixedIfNecessary } from "../../utils/TextFormat";
import { customers123 } from "../settings/CustomersList123";
import { addAddressCommaToString, moffettDetailsOptions } from "../transportRequisitions/dialog/TransportRequisitionsForm";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


export const phoneNumberPattern = /^07\d{8,9}$/;

const ImportExportExcel = () => {

    const dispatch = useDispatch();

    const s_users = useSelector(users);
    const s_user = useSelector(userDetails);
    const s_customerAddresses = useSelector(customerAddresses);

    const fileTypes = ["xlsx", "xls", "csv", "xlsm"];
    const correctFileName = 'Transport Bulk Upload v1.0.1.xlsx';


    const [file, setFile] = useState(null);
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isDataUploaded, setIsDataUploaded] = useState(true);
    const [customerIssueFound, setCustomerIssueFound] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const columns = ['Date notified' + '\n' + 'Format DD/MM/YYYY', 'Name', 'DELIVERY DATE REQUIRED' + '\n' + 'Format DD/MM/YYYY', 'Depot where goods are picked/located', 'Action', 'JOB NO.', 'Customer consignee',
        'Store/Property number', 'Project Name', 'Address', 'POSTCODE', 'Earliest Delivery Time' + '\n' + 'Format HH:MM', 'Latest Delivery Time' + '\n' + 'Format HH:MM', 'Sales Order Number', 'Description of goods', 'Pallet spaces',
        'Special instructions for delivery/collection site', 'Total weight', 'Moffett Required?', 'Max vehicle type permitted', 'Contact Name', 'Contact Number'];

    const handleChange = (file) => {
        setFile(file);
    };

    const handleCloseSnackbar = () => {
        setOpenError(false);
        setOpenSuccess(false);
    };


    const checkEmptyString = (str) => {
        if (str === null || str === '' || (str && str.length > 0 && str.replace(/\s/g, '').length === 0)) {
            return true;
        }
        return false;

    }

    const checkRow = (row) => {

        let indexesToCheckForEmptyString = [0, 1, 2, 3, 4, 5, 6, 12, 13, 15, 18, 20, 21];
        for (let i = 0; i < indexesToCheckForEmptyString.length; i++) {
            if (checkEmptyString(row[indexesToCheckForEmptyString[i]])) {
                setErrorMessage(columns[indexesToCheckForEmptyString[i]] + ' is a mandatory field');
                setOpenError(true);
                return false;
            }
        }

        if (!(row[0] instanceof Date) || !(row[2] instanceof Date)) {
            setErrorMessage('The file you are trying to upload contains non-formated dates. ' + '\n' + 'Please note that supported format for dates is DD-MM-YYYY');
            setOpenError(true);
            return false;
        }
        if (customers123.findIndex(el => el.toLowerCase() === row[6].toLowerCase()) <= -1) {
            setErrorMessage('The file you are trying to upload contains unknown customer names.');
            setOpenError(true);
            return false;
        }
        if (['DELIVERY', 'TSL COLLECTION', 'SSL COLLECTION', 'COLLECTION'].indexOf(row[4]) < 0) {
            setErrorMessage('The file you are trying to upload contains unknown type of requisitions. ' + '\n' + 'Please note that a transport requisition can be one of the following types: DELIVERY, TSL COLLECTION, SSL COLLECTION, COLLECTION.');
            setOpenError(true);
            return false;
        }
       
        if (moffettDetailsOptions.indexOf(row[18]) < 0) {
            setErrorMessage('The file you are trying to upload contains an unknown value for Moffett Required field.');
            setOpenError(true);
            return false;
        }
        if (!findCustomerAddress(row[6], row[7]) && row[7] && row[7] !== '' && (row[6].toLowerCase() === 'tesco' || row[6].toLowerCase() === "sainsbury's")) {
            if (row[6].toLowerCase() === 'tesco') {
                setCustomerIssueFound(2);
            }
            if (row[6].toLowerCase() === "sainsbury's") {
                setCustomerIssueFound(1);
            }
            if (!poscodePattern.test(row[10])) {
                setErrorMessage('The file you are trying to upload contains invalid postcodes.');
                setOpenError(true);
                return false;
            } else {
                setErrorMessage('The file you are trying to upload contains an unknown Store/Property number.');
                setOpenError(true);
            }

            return false;
        } else {
            if (!findCustomerAddress(row[6], row[7]) && row[7] && row[7] !== '' && (row[6].toLowerCase() !== 'tesco' && row[6].toLowerCase() !== "sainsbury's") && checkEmptyString(row[9])) { // Address when the customer is different than tesco/sainsburys
                setErrorMessage('Address is a mandatory field');
                setOpenError(true);
                return false;
            }
        }

        if (row[21] && !phoneNumberPattern.test(row[21].toString().replaceAll("'", "").replaceAll(" ", ""))) {
            setErrorMessage('The file you are trying to upload contains invalid phone numbers.');
            setOpenError(true);
            return false;
        }

        return true;
    }

    const findCustomerAddress = (customerName, storeNumber) => {
        if (customerName.toLowerCase() === 'tesco') {
            return s_customerAddresses.find(el => Number(el.customers_address_reference) === Number(storeNumber) && el.customer_id === 2);
        } else {
            if (customerName.toLowerCase() === "sainsbury's") {
                return s_customerAddresses.find(el => Number(el.customers_address_reference) === Number(storeNumber) && el.customer_id === 1);
            } else {
                return null;
            }
        }
    }

    const handleUpload = async () => {
        let ok = false;
        setIsDataUploaded(false);
        setErrorMessage('');
        let list = [];

        // if (file[0].name !== correctFileName) {
        //     setErrorMessage("Incorrect file.");
        //     setOpenError(true);
        //     setErrorFormat(true);
        //     setIsDataUploaded(true);
        //     return;
        // }


        readXlsxFile(file[0]).then(async (table) => {
            if (JSON.stringify(table[0]) !== JSON.stringify(columns)) {
                setErrorMessage("The file you are trying to upload is not matching the column description of the table.")
                setOpenError(true);
                setIsDataUploaded(true);
                return;
            }
            for (let i = 1; i < table.length; i++) {
                if (checkRow(table[i])) {
                    let currentUser = s_users.find(user => user.userName === table[i][1]);
                    let latestDeliveryDate = moment(table[i][2]).format("YYYY-MM-DD").toString();

                    let time1 = '';
                    let time2 = '';

                    if (typeof (table[i][11]) === 'string') {
                        time1 = table[i][11];
                    } else {
                        time1 = moment(table[i][11]).format('HH:mm');
                    }

                    if (typeof (table[i][12]) === 'string') {
                        time2 = table[i][12];
                    } else {
                        time2 = moment(table[i][12]).format('HH:mm');
                    }

                    // let time1 = moment(table[i][11]).format("HH:mm").toString();
                    // let time = moment(table[i][12]).format("HH:mm").toString();

                    // let time1 = moment().utcOffset(0);
                    // time1.set({ hours: table[i][11].split(':')[0], minute: table[i][11].split(':')[1] });
                    // time1.toISOString()
                    // time1.format("HH:mm")

                    // let time2 = moment().utcOffset(0);
                    // time2.set({ hours: table[i][12].split(':')[0], minute: table[i][12].split(':')[1] });
                    // time2.toISOString()
                    // time2.format("HH:mm")
                    let action = '';
                    switch (table[i][4]) {
                        case "DELIVERY":
                            action = 'Delivery'
                            break;
                        case "SITE COLLECTION":
                            action = 'Site Collection'
                            break;
                        case "PLANT COLLECTION":
                            action = 'Plant Collection'
                            break;
                        case "COLLECTION":
                            action = 'Collection'
                            break;
                        case "SSL COLLECTION":
                            action = 'SSL Collection'
                            break;
                        case "TSL COLLECTION":
                            action = 'TSL Collection'
                            break;
                        default:
                            break;
                    }

                    let foundAddress = null;
                    let foundCustomerAddress = findCustomerAddress(table[i][6], table[i][7]);

                    if (foundCustomerAddress) {
                        const foundAddressResponse = await getRequest(`${erpSystemURL}/addresses/Search?where=id=${foundCustomerAddress.customer_address_id}`, true);
                        foundAddress = foundAddressResponse.response[0];
                    }
                    let concatAddress = '';
                    if (foundAddress) {
                        concatAddress = addAddressCommaToString(foundAddress.address1) + addAddressCommaToString(foundAddress.address2) + addAddressCommaToString(foundAddress.address3) + addAddressCommaToString(foundAddress.address4, true);
                        if (concatAddress[concatAddress.length - 2] === ',' && concatAddress[concatAddress.length - 1] === ' ') {
                            concatAddress = concatAddress.slice(0, -2);
                        }
                    }
                    list.push({
                        dateNotified: formatDate(table[i][0]),
                        userId: currentUser ? currentUser.uid : table[i][1],
                        earliestDelivery: latestDeliveryDate + ' ' + time1,
                        latestDelivery: latestDeliveryDate + ' ' + time2,
                        transportDepot: action === 'Delivery' ? table[i][3] : 'N/A',
                        returnFacility: action !== 'Delivery' ? table[i][3] : 'N/A',
                        action: action,
                        jobNo: table[i][5],
                        customerName: customers123.find(el => el.toLowerCase() === table[i][6].toLowerCase()),
                        customerAddressID: foundCustomerAddress ? foundCustomerAddress.id : null,
                        propertyNumber: table[i][6].toLowerCase() === 'tesco' || table[i][6].toLowerCase() === "sainsbury's" ? null : table[i][7],
                        projectName: table[i][8],
                        address: foundAddress ?
                            concatAddress : table[i][9],
                        postcode: foundAddress ? foundAddress.post_code : table[i][10],
                        salesOrderNumber: table[i][13],
                        description: table[i][14],
                        palletSpaces: table[i][15],
                        specialInstructions: table[i][16],
                        totalWeight: table[i][17],
                        moffettDetails: table[i][18],
                        maxVehicleType: table[i][19],
                        deliveryContact: table[i][20],
                        deliveryContactNumber: table[i][21].toString().replaceAll("'", "")
                    });
                } else {
                    ok = true;
                    setIsDataUploaded(true);
                    break;
                }
            }
            if (!ok) {
                let dto = {
                    tableDest: 'transport_request',
                    excelData: list
                }
                console.log('List to upload', list);
                const response = await postRequest(`${transportRequestURL}/importExcel`, dto)
                    .catch((error) => {
                        console.error('error', error);
                        setOpenError(true);
                        setErrorMessage("A problem has occured while importing excel data into the database.")
                        setIsDataUploaded(true);
                    });
                if (response.success) {
                    setFile(null);
                    setIsDataUploaded(true);
                    dispatch(setRequisitions(response.response));
                    setSuccessMessage("Excel data imported successfully.")
                    setOpenSuccess(true);
                    sendUploadNotificationEmail(list, file);
                } else {
                    if (response.status === 505) {
                        dispatch(setAppVersion('CONFLICT'));
                    } else {
                        setOpenError(true);
                        setIsDataUploaded(true);
                        setErrorMessage("A problem has occured while importing excel data into the database.")
                    }
                }
            }

        })
        setOpenError(false);
    }

    const sendUploadNotificationEmail = async (list, file) => {
        let emailAddresses = devMode ? [
            {
                "email": s_user.userEmail,
                "name": s_user.userNamed
            }] : [
            {
                "email": "transport@fitoutuk.com",
                "name": "Admin Transport"
            },
            {
                "email": "Transport@prl-logistics.com",
                "name": "Transport"
            },
            {
                "email": "derekmorley@fitoutuk.com",
                "name": "Derek Morley"
            },
            {
                "email": s_user.userEmail,
                "name": s_user.userName
            }]
        const data = {
            emailAddresses: emailAddresses,
            subject: 'Transport Tracker - New Bulk Upload ',
            list: list,
            creator: s_user.userName
        }
        const createEmailTemplate = await postRequest(`${transportRequestURL}/email/uploadNotification`, data)
            .catch(error => {
                console.error(error);
            });
        if (createEmailTemplate.success) {

            const formData = new FormData();
            let index = 0;
            if (file) {
                Array.from(file).forEach(file => {
                    formData.append(index.toString(), file);
                    index++;
                });
                formData.append("fileCount", index);
            }
            index = 0
            emailAddresses.forEach(address => {
                formData.append('ae' + index.toString(), address.email);
                formData.append('an' + index.toString(), address.name);
                index++
            })
            formData.append("subject", createEmailTemplate.response.Subject);
            formData.append("body", createEmailTemplate.response.Body);
            formData.append("emailAddressesCount", emailAddresses.length);

            const request = new XMLHttpRequest();
            request.open("POST", `${transportRequestURL}/email/emailWithFiles`);
            request.send(formData);
            setFile(null);
        }
    }

    const handleDownload = () => {
        saveAs(
            excelFile,
            correctFileName
        );
    }

    const handleDownloadTracker = async () => {

        let start = new Date(startDate);
        start.setHours(0, 0, 0);
        let end = new Date(endDate);
        end.setHours(23, 59, 59);
        const response = await postRequest(`${transportRequestURL}/getRequests`, { showOnly: ['Delivery', 'Collection', 'SSL Collection', 'TSL Collection'], startDate: formatDate(start), endDate: formatDate(end), filterBy: null })
            .catch((error) => {
                console.error('error', error);
            });
        if (response.success) {
            excelExport(response.response, s_users);
        }

    }

    const excelExport = (entries, users) => {
        var ExcelJSWorkbook = new ExcelJS.Workbook();
        var columns = [
            "Action",
            "Customer Name",
            "Project Name",
            "Job No",
            "Sales Order Number",
            "Project Number / CPAR",
            "Address",
            "Postcode",
            "Earliest Delivery Date",
            "Latest Delivery Date",
            "Date Notified",
            "Created by",
            "Project Main Contractor",
            "Delivery Contact Name",
            "Delivery Contact Number",
            "Location of Equipment",
            "Forklift Available",
            "Moffett required",
            "Labour on site",
            "Dedicated Vehicle",
            "Description",
            "Special Instructions",
            "SDR Form Received",
            "SDR Form Link",
            "Return Facility",
            "Loading Depot Header",
            "Designate Person for loading",
            "Driver Loading Time",
            "Qty Standard Pallets",
            "Qty Oversized Pallets",
            "Total Weight",
            "Pallet Spaces",
            "Max Vehicle type permited",
            "Despatch Note no"
        ];

        let start = new Date(startDate);
        while (start <= endDate) {
            let worksheet = ExcelJSWorkbook.addWorksheet(formatDateWithoutHours(start).toString());

            let dataRows = [];
            entries.filter(el => formatDateWithoutHours(new Date(el.latestDelivery)) === formatDateWithoutHours(start)).forEach((el, index) => {
                let dataRow = [
                    el.action,
                    el.customerName,
                    el.projectName,
                    el.jobNo,
                    el.salesOrderNumber,
                    el.projectNumberCpar,
                    el.address,
                    el.postcode,
                    el.earliestDelivery,
                    el.latestDelivery,
                    el.dateNotified,
                    el.userId ? users.find(user => user.uid === el.userId) ? users.find(user => user.uid === el.userId).userName : el.userId : '',
                    el.projectMainContractor,
                    el.deliveryContact,
                    el.deliveryContactNumber,
                    el.equipmentLocation,
                    el.forkliftAvailable,
                    el.moffettDetails,
                    el.labourOnSite,
                    el.dedicatedVehicleRequired,
                    el.description,
                    el.specialInstructions,
                    el.sdrFormReceived,
                    el.sdrFormLink,
                    el.returnFacility,
                    el.transportDepot,
                    el.loadingResponsiblePerson,
                    el.driverLoadingTime,
                    el.qtyPalletStandard,
                    el.qtyPalletOversized,
                    el.totalWeight,
                    el.palletSpaces,
                    el.maxVehicleType,
                    el.id
                ];
                dataRows.push(dataRow)
            });
            worksheet.mergeCells("A2:I2");

            const customCell = worksheet.getCell("A2");
            customCell.font = {
                name: "Calibri",
                family: 4,
                size: 20,
                bold: true
            };

            customCell.value = "Transport Tracker " + formatDateWithoutHoursUKFormat(new Date(start));

            var headerRow = worksheet.addRow();
            var headerRow = worksheet.addRow();
            worksheet.getRow(4).font = { bold: true };

            for (let i = 0; i < columns.length; i++) {
                let cell = headerRow.getCell(i + 1);
                cell.value = columns[i];
            }

            for (let i = 0; i < dataRows.length; i++) {
                var dataRow = worksheet.addRow();
                for (let j = 0; j < dataRows[i].length; j++) {
                    let cell = dataRow.getCell(j + 1);
                    cell.value = dataRows[i][j];
                }
            }

            start.setDate(start.getDate() + 1)
        }

        ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                "Transport Tracker " + formatDateWithoutHours(new Date(startDate)) + ".xlsx"
            );
        });

    };

    const [searchField, setSearchField] = useState('');
    const arrayInChunks = (array, chunkSize) => {
        let a = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            a.push(chunk)
        }
        return a;
    }

    const importSupplierCompanyAddresses = () => {
        let list = [];
        readXlsxFile(file[0]).then(async (table) => {
            for (let i = 1; i < table.length; i++) {
                list.push({
                    SupplierID: table[i][0],
                    AbbeyDepot: toFixedIfNecessary(table[i][1], 3),
                    BarnsleyDepot: toFixedIfNecessary(table[i][2], 3),
                    ShepcoteDepot: toFixedIfNecessary(table[i][3], 3)
                });
            }

            // const response = await postRequest(`${transportRequestURL}/importExcel`, dto)
            //     .catch((error) => {
            //         console.error('error', error);
            //         setOpenError(true);
            //         setErrorMessage("A problem has occured while importing excel data into the database.")
            //         setIsDataUploaded(true);
            //     });


        })
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Paper sx={{ height: '85vh', p: 1 }}>
                        <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>IMPORT</Divider>
                        <Stack spacing={1} sx={{ display: "flex", flexDirection: "column", alignItems: "left", marginLeft: '1em' }} >
                            <Typography> Please upload the excel file.</Typography>
                            <FileUploader
                                multiple={true}
                                handleChange={handleChange}
                                name="file"
                                types={fileTypes}
                            />
                            {file && file[0] && <Typography sx={{ fontStyle: 'italic' }}> File name: {file[0].name}</Typography>}
                            {file === null && <Typography sx={{ fontStyle: 'italic', color: 'red' }}> No files uploaded yet</Typography>}
                            <span /><span /><span />
                            <Button size='small' variant='contained' color='primary' onClick={handleUpload} disabled={file === null || file[0] === null} sx={{ width: 150 }}>Upload</Button>
                            {/* <Button size='small' variant='contained' color='primary' onClick={importSupplierCompanyAddresses} disabled={file === null || file[0] === null} sx={{ width: 150 }}>Upload Suppliers</Button> */}
                            {errorMessage === 'The file you are trying to upload contains unknown customer names.' &&
                                <Paper style={{ maxHeight: window.innerHeight - 400, overflow: 'auto' }}>
                                    <Stack spacing={1} sx={{ p: 1 }}>
                                        <Typography variant="subtitle2" fontWeight={'bold'}>List of all customer names</Typography>
                                        <Stack spacing={1} direction="row" >
                                            <FilterAltIcon sx={{ fontSize: 32 }}></FilterAltIcon>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="searchFileld"
                                                name="searchField"
                                                label="Search requisition ..."
                                                type="text"
                                                size="small"
                                                value={searchField}
                                                onChange={(e) => { setSearchField(e.target.value) }}
                                                sx={{ width: 250 }}
                                            />
                                        </Stack>
                                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                            {customers123 && customers123.filter(el => el.toLowerCase().indexOf(searchField.toLowerCase()) > -1).map((value) => (
                                                <ListItem
                                                    key={value}
                                                    sx={{
                                                        maxHeight: '30px'
                                                    }}
                                                    disableGutters
                                                >
                                                    <ListItemText primary={value} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Stack>

                                </Paper>
                            }
                            {errorMessage === 'The file you are trying to upload contains an unknown value for Moffett Required field.' &&
                                <Paper style={{ maxHeight: window.innerHeight - 400, overflow: 'auto' }}>
                                    <Stack spacing={1} sx={{ p: 1 }}>
                                        <Typography variant="subtitle2" fontWeight={'bold'}>List of supported values for Moffett Required field</Typography>
                                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                            {moffettDetailsOptions.map((value) => (
                                                <ListItem
                                                    key={value}
                                                    sx={{
                                                        maxHeight: '30px'
                                                    }}
                                                    disableGutters
                                                >
                                                    <ListItemText primary={value} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Stack>

                                </Paper>
                            }

                            {/* {errorMessage === 'The file you are trying to upload contains an unknown Store/Property number.' &&
                                <Paper style={{ maxHeight: window.innerHeight - 400, overflow: 'auto' }}>
                                    <Stack spacing={1} sx={{ p: 1 }}>
                                        <Typography variant="subtitle2" fontWeight={'bold'}>List of all stores for {customerIssueFound === 'TESC001' ? 'Tesco' : "Sainsbury's"}</Typography>
                                        <Stack spacing={1} direction="row" >
                                            <FilterAltIcon sx={{ fontSize: 32 }}></FilterAltIcon>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="searchFileld"
                                                name="searchField"
                                                label="Search address ..."
                                                type="text"
                                                size="small"
                                                value={searchField}
                                                onChange={(e) => { setSearchField(e.target.value) }}
                                                sx={{ width: 250 }}
                                            />
                                        </Stack>
                                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                            {s_customerAddresses
                                                .filter(el => el.customer_id === customerIssueFound)
                                                .filter(el =>
                                                    el.customers_address_reference?.toString().toLowerCase().indexOf(searchField.toLowerCase()) > -1 ||
                                                    el.customer_address_name?.toLowerCase().indexOf(searchField.toLowerCase()) > -1 ||
                                                    (s_addresses.find(elem => elem.id === el.customer_address_id) && s_addresses.find(elem => elem.id === el.customer_address_id).post_code?.toLowerCase().indexOf(searchField.toLowerCase()) > -1)
                                                ).map((value) => (
                                                    <Box>
                                                        {
                                                            s_addresses.find(elem => elem.id === value.customer_address_id) &&
                                                            <ListItem
                                                                key={value.id}
                                                                sx={{
                                                                    maxHeight: '50px'
                                                                }}
                                                                disableGutters
                                                            >
                                                                <ListItemText
                                                                    primary={value.customers_address_reference + ' - ' + value.customer_address_name}
                                                                    secondary={
                                                                        addAddressCommaToString(s_addresses.find(elem => elem.id === value.customer_address_id)?.address1) +
                                                                        addAddressCommaToString(s_addresses.find(elem => elem.id === value.customer_address_id)?.address2) +
                                                                        addAddressCommaToString(s_addresses.find(elem => elem.id === value.customer_address_id)?.address3) +
                                                                        addAddressCommaToString(s_addresses.find(elem => elem.id === value.customer_address_id)?.address4) +
                                                                        s_addresses.find(elem => elem.id === value.customer_address_id)?.post_code
                                                                    } />
                                                            </ListItem>
                                                        }
                                                    </Box>
                                                ))}
                                        </List>
                                    </Stack>

                                </Paper>
                            } */}

                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={5}>
                    <Paper sx={{ height: '85vh', p: 1 }}>
                        <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>EXPORT</Divider>
                        <Stack spacing={1} sx={{ display: "flex", flexDirection: "column", alignItems: "left", marginLeft: '1em' }} >
                            <Typography> Please select the date for Transport Tracker export.</Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Stack direction={'row'} spacing={1}>
                                    <DatePicker 
                                        label="Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate || null}
                                        onChange={(newValue) => { setStartDate(newValue); setEndDate(newValue) }}
                                        renderInput={(params) => <TextField sx={{ width: 200 }} {...params} />}
                                    />
                                    <DatePicker 
                                        label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate || null}
                                        onChange={(newValue) => { setEndDate(newValue) }}
                                        renderInput={(params) => <TextField sx={{ width: 200 }} {...params} />}
                                        minDate={startDate}
                                        maxDate={moment(startDate).add(1, 'M')}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <span /><span /><span />
                            <Button size='small' variant='contained' color='primary' onClick={handleDownloadTracker} disabled={startDate === null || endDate === null} sx={{ width: 150 }}>Download</Button>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid >
            {(!isDataUploaded ? <LinearProgress /> : null)}
            <Snackbar open={openError} autoHideDuration={10000} onClose={handleCloseSnackbar}>
                <Alert severity="error" sx={{ width: '100%' }} variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="success" sx={{ width: '100%' }} variant="filled">
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box >
    );
}

export default ImportExportExcel;