import React, { useState } from 'react'
import DateRangeSelector from '../selectors/DateRangeSelector';
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';
import moment from 'moment';
import { getRequest, postRequest, transportRequestURL } from '../../db/apiHandler';
import { formatDate, formatDateWithoutHours, formatDateWithoutHoursUKFormat } from '../../utils/DateFormat';
import { useDispatch } from 'react-redux';
import { setAppMessage, setAppVersion } from '../../store/appSlice';
import { Alert, Box, Divider, IconButton, LinearProgress, Menu, MenuItem, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { NoWrapType } from '../../utils/NoWrapType';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FirebasePODsDialog from '../transportRequisitions/dialog/FirebasePODsDialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";

const PodReport = () => {
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState({
        startDate: moment().set({ hour: 0, minute: 0, second: 0 }),
        endDate: moment().set({ hour: 23, minute: 59, second: 59 })
    });

    const [showOnly, setShowOnly] = useState(['Delivery', 'Collection', 'SSL Collection', 'TSL Collection']);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [requsitions, setRequisitions] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [progress, setPorgress] = useState(0);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleDateChange = (date) => {
        let start = moment(date).set({ hour: 0, minute: 0, second: 0 });
        let end = moment(date).set({ hour: 23, minute: 59, second: 59 });
        setDateRange({ ...dateRange, startDate: start, endDate: end });
        loadData(start, end);
    }

    const loadData = async (newStartDate, newEndDate) => {
        setRequisitions([]);
        setSelectedRow(null);
        setIsDataLoading(true);
        let start = new Date(newStartDate);
        start.setHours(0, 0, 0);
        let end = new Date(newEndDate);
        end.setHours(23, 59, 59);
        try {
            const response = await postRequest(`${transportRequestURL}/getRequests`, { showOnly: showOnly, startDate: formatDate(start), endDate: formatDate(end) })
                .catch((error) => {
                    console.error('error', error);
                });

            if (response.success) {

                const storage = getStorage();
                let reqs = response.response;
                for (let i = 0; i < reqs.length; i++) {
                    let el = reqs[i];
                    await getRequest(`https://fitoutukapis.com/foukAPIs/testAPI/db/testDatamart/${el.id}`)
                        .then(async (response) => {
                            if (response.status === 200) {
                                if (response.response.length > 0) {
                                    el.has_flexipod = true;
                                    el.flexipodURL = response.response[0].TrackingURL;
                                    // podFound = true;
                                }
                            }
                        })
                        .catch(err => {
                            console.error(err)
                            setOpenErrorSnackbar(true);
                            setErrorMessage('Error Getting Tracking URL');
                            dispatch(setAppMessage({ open: true, severity: 'error', message: 'Error Getting Tracking URL' }))
                        })

                    let directoryPath = `fitoutuk/transport/${el.id}/POD`;

                    const dirRef = ref(storage, directoryPath);
                    const res = await listAll(dirRef);
                    const fileNamesFound = res.items.map(item => item.name);
                    if (fileNamesFound.length > 0) {
                        el.has_firebase = true;
                        el.firebase_files = fileNamesFound;
                    } else {
                        el.has_firebase = false;
                        el.firebase_files = null;
                    }
                    setPorgress(100 * i / reqs.length)
                }

                setRequisitions(reqs.sort(dynamicSort('id')));
            } else {
                if (response.status === 505) {
                    dispatch(setAppVersion('CONFLICT'));
                }
            }

        } catch (error) {

        }

        setIsDataLoading(false);
    }

    const selectRow = (row) => {
        if (selectedRow && selectedRow.id === row.id) {
            setSelectedRow(null);
        } else {
            setSelectedRow(row);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const openPODoptions = Boolean(anchorEl);

    const [fileNames, setFileNames] = useState(null);
    const [openDialogFirebasePODs, setOpenDialogFirebasePODs] = useState(false);

    const handleClick = (event) => {
        if (anchorEl) {
            setAnchorEl(null)
        } else {
            setAnchorEl(event.currentTarget);
        }
    };


    const handleOpenFlexipodTracking = async (id, postcode) => {

        // let podFound = false;

        // try to find flexipod POD
        await getRequest(`https://fitoutukapis.com/foukAPIs/testAPI/db/testDatamart/${id}`)
            .then(async (response) => {
                if (response.status === 200) {
                    if (response.response.length > 0) {
                        navigator.clipboard.writeText(postcode).catch(err => console.error('Could not copy text: ', err));
                        window.open(response.response[0].TrackingURL, '_blank').focus();
                        // podFound = true;
                    } else {
                        setErrorMessage('No Tracking URL found');
                        setOpenErrorSnackbar(true);
                    }
                }
            })
            .catch(err => {
                console.error(err)
                setErrorMessage('Error Getting Tracking URL');
                setOpenErrorSnackbar(true);
                dispatch(setAppMessage({ open: true, severity: 'error', message: 'Error Getting Tracking URL' }))
            })

        setAnchorEl(null);

    }

    const handleLoadFirebasePOD = async (id) => {
        const storage = getStorage();
        let directoryPath = `fitoutuk/transport/${id}/POD`;

        const dirRef = ref(storage, directoryPath);
        const res = await listAll(dirRef);
        const fileNamesFound = res.items.map(item => item.name);
        let filePath = '';

        if (fileNamesFound.length === 0) {

            setErrorMessage('No files found');
            setOpenErrorSnackbar(true);
            dispatch(setAppMessage({ open: true, severity: 'error', message: 'No files found' }))
        } else {
            if (fileNamesFound.length === 1) {
                filePath = directoryPath + '/' + fileNamesFound[0];
                let fileRef = null;
                fileRef = ref(storage, filePath);
                const downloadURL = await getDownloadURL(fileRef);
                window.open(downloadURL, '_blank').focus();
                // const response = await fetch(downloadURL);
            } else {
                setFileNames(fileNamesFound);
                setOpenDialogFirebasePODs(true);
            }
        }

        setAnchorEl(null);

    }

    const handleCloseSnackbar = () => {
        setOpenErrorSnackbar(false);
    };

    const handleDownload = () => {
        var ExcelJSWorkbook = new ExcelJS.Workbook();
        var columns = [
            "Transport Request ID",
            "Postcode",
            "Has Flexipod URL",
            "Has Firebase POD"
        ];
        
        let worksheet = ExcelJSWorkbook.addWorksheet(dateRange.startDate.format('DD-MM-YYYY').toString());
        let dataRows = [];
        requsitions.forEach(el => {
            let dataRow = [
                el.id,
                el.postcode,
                el.has_flexipod ? 'YES' : 'NO',
                el.has_firebase ? 'YES' : 'NO'
            ];
            dataRows.push(dataRow);
        })
        
        worksheet.mergeCells("A2:I2");
        const customCell = worksheet.getCell("A2");
        customCell.font = {
            name: "Calibri",
            family: 4,
            size: 20,
            bold: true
        };

        customCell.value = "POD Report " + dateRange.startDate.format('DD/MM/YYYY');
        
        var headerRow = worksheet.addRow();
        var headerRow = worksheet.addRow();
        worksheet.getRow(4).font = { bold: true };

        for (let i = 0; i < columns.length; i++) {
            let cell = headerRow.getCell(i + 1);
            cell.value = columns[i];
        }
          
        for (let i = 0; i < dataRows.length; i++) {
            var dataRow = worksheet.addRow();
            for (let j = 0; j < dataRows[i].length; j++) {
                let cell = dataRow.getCell(j + 1);
                cell.value = dataRows[i][j];
            }
        }
        ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                "POD Report " + dateRange.startDate.format('DD-MM-YYYY') + ".xlsx"
            );
        });

    }

    return (
        <Stack spacing={1} sx={{ p: 1 }}>
            <Typography sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }} variant="h4">POD Report</Typography>
            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="Date"
                        format='DD/MM/YYYY'
                        value={moment(dateRange.startDate)}
                        onChange={(newValue) => {
                            handleDateChange(moment(newValue));
                        }}
                        renderInput={(params) => <TextField {...params} value={moment(dateRange.startDate)} size='small' />}
                    />
                </LocalizationProvider>
                <Stack direction={'row'} spacing={1}>
                    {selectedRow &&
                        <Tooltip title='POD'>
                            <IconButton
                                color="primary"
                                onClick={handleClick}
                            // onClick={() => handleOpenFlexipodTracking(selectedRows[0].id, selectedRows[0].postcode)}
                            >
                                <ReceiptLongIcon />
                                {/* <img src={PodLogo} alt="Pod Logo" style={{ width: '24px', height: '24px' }} /> */}
                            </IconButton>
                        </Tooltip>}
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={openPODoptions}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleOpenFlexipodTracking(selectedRow.id, selectedRow.postcode)}>Flexipod URL</MenuItem>
                        <MenuItem onClick={() => handleLoadFirebasePOD(selectedRow.id)}>Firebase POD</MenuItem>
                    </Menu>
                    {requsitions.length > 0 && <IconButton
                        color="primary"
                        onClick={handleDownload}
                    // onClick={() => handleOpenFlexipodTracking(selectedRows[0].id, selectedRows[0].postcode)}
                    >
                        <FileDownloadIcon />
                        {/* <img src={PodLogo} alt="Pod Logo" style={{ width: '24px', height: '24px' }} /> */}
                    </IconButton>}
                </Stack>

            </Stack>

            {/* <DateRangeSelector handleDateChange={handleDateChange} maxOneMonth={true} variant={'contained'} /> */}

            {isDataLoading ?
                <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={progress} />
                </Box> : requsitions.length > 0 &&
                <TableContainer sx={{ overflow: 'hidden' }}>
                    <Table size="small" aria-label="a dense table" sx={{ marginLeft: '10px', marginRight: '10px' }}>
                        <TableHead sx={{
                            backgroundColor: 'white',
                            color: 'primary.text'
                        }}>
                            <TableRow>
                                <TableCell sx={{ color: "inherit", width: 150, fontWeight: 'bold' }} align="left">Transport Request ID</TableCell>
                                <TableCell sx={{ color: "inherit", width: 150, fontWeight: 'bold' }} align="left">Postcode</TableCell>
                                <TableCell sx={{ color: "inherit", width: 150, fontWeight: 'bold' }} align="left">Has Flexipod URL</TableCell>
                                <TableCell sx={{ color: "inherit", width: 150, fontWeight: 'bold' }} align="left">Has Firebase POD</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requsitions && (rowsPerPage > 0
                                ? requsitions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : requsitions
                            ).map((row, index) => (
                                <TableRow
                                    hover={selectedRow && selectedRow.id === row.id ? false : true}
                                    onClick={() => { selectRow(row) }}
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        cursor: selectedRow && selectedRow.id === row.id && 'pointer',
                                        backgroundColor: selectedRow && selectedRow.id === row.id && /*color(row, 'clientId')*/ 'slategrey',
                                        color: selectedRow && selectedRow.id === row.id && 'primary.contrastText',
                                    }}
                                >
                                    <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.id} /></TableCell>
                                    <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.postcode} /></TableCell>
                                    <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.has_flexipod ? <CheckIcon /> : <CloseIcon />} /></TableCell>
                                    <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.has_firebase ? <CheckIcon /> : <CloseIcon />} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={requsitions ? requsitions.length : -1}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>}

            {openDialogFirebasePODs && <FirebasePODsDialog id={selectedRow.id} fileNames={fileNames} setFileNames={setFileNames} open={openDialogFirebasePODs} setOpen={setOpenDialogFirebasePODs} />}
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="error" sx={{ width: '100%' }} variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Stack>
    )
}


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export const dynamicSort = (key) => {
    var sortOrder = 1;
    if (key[0] === "-") {
        sortOrder = -1;
        key = key.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[key] < b[key]) ? -1 : (a[key] > b[key]) ? 1 : 0;
        return result * sortOrder;
    }
}
export default PodReport
